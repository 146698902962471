import driverReducer from "./features/drivers/driversSlice"
import purchaserReducer from "./features/purchasers/purchasersSlice"
import assortmentReducer from './features/assortments/assortmentsSlice'
import messagesReducer from "./features/messages/messagesSlice"
import transactionsReducer from "./features/transactions/transactionsSlice"
import projectReducer from "./features/projects/projectsSlice"
import equipmentReducer from "./features/equipment/equipmentSlice"
import userReducer from "./features/user/userSlice"
import UIReducer from "./features/UI/UISlice"
import {combineReducers} from "redux";
import {configureStore } from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {versionNr} from "./version"

import LogRocket from 'logrocket';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from 'redux-persist/lib/storage' // default: localStorage if web, AsyncStorage if react-native

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createMigrate from 'redux-persist/lib/createMigrate'

const migrations = {
  [versionNr.toString()]:  (state: any) => {
    console.log("New version: resetting persist state")
    return {_persist: state._persist}; // reset all state on new version
  },
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['sidebarState'],
  version: versionNr,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true })
}

const combinedReducer = combineReducers({
  assortments: assortmentReducer,
  drivers: driverReducer,
  equipment: equipmentReducer,
  purchasers: purchaserReducer,
  transactions: transactionsReducer,
  projects: projectReducer,
  user: userReducer,
  UI: UIReducer,
  messages: messagesReducer,
});

//@ts-ignore
const rootReducer = persistReducer<typeof combinedReducer, any>(persistConfig, combinedReducer)

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(LogRocket.reduxMiddleware()),
})

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()  // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store;
