/**
 * Created by mathi on 12-1-2018.
 */
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function ChatMessage({ message }: { message: any }) {
  const { t } = useTranslation();
  const incoming = message.user === null;
  const send = dayjs(message.send);
  return (
    <div className="row">
      <div
        className={'message ' + (incoming ? 'incoming' : 'outgoing pull-right')}
      >
        <p>{message.content}</p>
        <span className="time">{send.format('DD-MM-YYYY HH:mm')}</span>
        <span className={'status label ' + message.status}>
          {t(message.status)}
        </span>
      </div>
    </div>
  );
}

export default ChatMessage;
