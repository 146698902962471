/**
 * Created by mathi on 12-1-2018.
 */
import React from 'react';
import {
  setNavigationBroken,
  setNavigationCollapsed
} from '../../features/UI/UISlice';
import { get_navigation, get_sidebar } from '../../features/UI/UISelectors';
import { Link } from 'react-router-dom';
import logo from './logo.svg';
import logo_collapsed from './logo_collapsed.svg';
import { useLocation } from 'react-router-dom';
import { getHeaderItems } from '../Header/Header';
import {
  ApartmentOutlined,
  BarChartOutlined,
  GlobalOutlined,
  GoldOutlined,
  HomeOutlined,
  ProjectOutlined,
  HeatMapOutlined,
  ReconciliationOutlined,
  TableOutlined,
  ClockCircleOutlined,
  WalletOutlined,
  TeamOutlined
} from '@ant-design/icons';

import { Button, theme } from 'antd';

const { useToken } = theme;
import { Layout, Menu } from 'antd';
import './Navigation.less';
import {
  get_user,
  permissionCheckRule,
  usePermissionCheck
} from '../../features/user/userSelectors';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../Store';

const { SubMenu } = Menu;
const { Sider } = Layout;

function Navigation() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const user = useAppSelector(get_user);

  const { t } = useTranslation();
  const checkPermission = usePermissionCheck();
  const navigation = useAppSelector(get_navigation);
  const headerItems = getHeaderItems();

  const collapsed = {
    defaultCollapsed: navigation.collapsed,
    collapsed: navigation.collapsed
  };

  const header = {
    key: 'logo',
    style: { marginBottom: 12, marginTop: 12 },
    title: '',
    label: (
      <Link to="/">
        <img
          src={logo}
          alt="TimberTracer"
          height={25}
          width={146}
          style={{
            display: !navigation.collapsed ? 'inherit' : 'none'
          }}
        />
        <img
          src={logo_collapsed}
          alt="TimberTracer"
          height={25}
          width={25}
          style={{
            display: navigation.collapsed ? 'inherit' : 'none'
          }}
        />
      </Link>
    )
  };

  const home = {
    key: '/',
    label: (
      <Link to="/">
        <HomeOutlined />
        <span>Home</span>
      </Link>
    )
  };

  const map = checkPermission('UserManagement.view_driverlocation')
    ? {
        key: '/map',
        label: (
          <Link to="/map">
            <GlobalOutlined />
            <span>{t('Map')}</span>
          </Link>
        )
      }
    : null;

  const stock = checkPermission('MessageManagement.view_transaction')
    ? {
        key: '/stock',
        label: (
          <span>
            <ApartmentOutlined />
            <span>{t('Stock')}</span>
          </span>
        ),
        children: [
          {
            key: '/stock/overview',
            label: (
              <Link to="/stock/overview">
                <TableOutlined />
                <span>{t('Table')}</span>
              </Link>
            )
          },
          {
            key: '/stock/summary',
            label: (
              <Link to="/stock/summary">
                <BarChartOutlined />
                <span>{t('Summary')}</span>
              </Link>
            )
          }
        ]
      }
    : null;

  const equipment = user?.companies_organizationuser?.some(
    (user) => user.organization.id == 1
  )
    ? {
        key: '/equipment',
        label: (
          <span>
            <WalletOutlined />
            <span>{t('Equipment')}</span>
          </span>
        ),
        children: [
          {
            key: '/equipment/production/file',
            label: (
              <Link to="/equipment/production/file">
                <HeatMapOutlined />
                <span>{t('Production')}</span>
              </Link>
            )
          },
          {
            key: '/equipment/hours',
            label: (
              <Link to="/equipment/hours">
                <ClockCircleOutlined />
                <span>{t('Hours')}</span>
              </Link>
            )
          }
        ]
      }
    : null;

  const projects = checkPermission('ProjectManagement.view_project')
    ? {
        key: '/projects',
        label: (
          <Link to="/projects">
            <ProjectOutlined />
            <span>{t('Projects')}</span>
          </Link>
        )
      }
    : null;

  const purchasers = checkPermission('Companies.view_forestcompanypurchasers')
    ? {
        key: '/purchasers',
        label: (
          <span>
            <ApartmentOutlined />
            <span>{t('Purchasers')}</span>
          </span>
        ),
        children: [
          {
            key: '/purchasers/list',
            label: (
              <Link to="/purchasers/list">
                <TableOutlined />
                <span>{t('List')}</span>
              </Link>
            )
          },
          {
            key: '/purchasers/statistics',
            label: (
              <Link to="/purchasers/statistics">
                <BarChartOutlined />
                <span>{t('Statistics')}</span>
              </Link>
            )
          }
        ]
      }
    : null;

  const wood = checkPermission(
    [
      'ProjectManagement.view_conversiontable',
      'ProjectManagement.view_assortment',
      'ProjectManagement.view_assortment'
    ],
    permissionCheckRule.ANY
  )
    ? {
        key: '/wood',
        label: (
          <span>
            <GoldOutlined />
            <span>{t('Wood')}</span>
          </span>
        ),
        children: [
          checkPermission('ProjectManagement.view_assortment')
            ? {
                key: '/wood/assortment',
                label: <Link to="/wood/assortment">{t('Assortments')}</Link>
              }
            : null,
          checkPermission('ProjectManagement.view_conversiontable')
            ? {
                key: '/projects/conversiontable',
                disabled: true,
                label: (
                  <Link to="/wood/conversiontable">
                    {t('Conversiontables')}
                  </Link>
                )
              }
            : null
        ]
      }
    : null;

  const users = checkPermission(
    [
      'UserManagement.view_membership',
      'UserManagement.view_driver',
      'perms.UserManagement.view_purchaser'
    ],
    permissionCheckRule.ANY
  )
    ? {
        key: '/users',
        label: (
          <span>
            <TeamOutlined />
            <span>{t('Users')}</span>
          </span>
        ),
        children: [
          checkPermission('UserManagement.view_membership')
            ? {
                key: '/users/membership',
                label: <Link to="/users/membership">{t('Memberships')}</Link>
              }
            : null,
          checkPermission('UserManagement.view_driver')
            ? {
                key: '/users/driver',
                label: <Link to="/users/driver">{t('Drivers')}</Link>
              }
            : null,
          checkPermission('UserManagement.view_purchaser')
            ? {
                key: '/users/purchaser/list/',
                label: (
                  <Link to="/users/purchaser/list/">{t('Purchasers')}</Link>
                )
              }
            : null
        ]
      }
    : null;

  const organization = checkPermission('UserManagement.view_organization')
    ? {
        key: '/users/organization',
        label: (
          <Link to="/users/organization">
            <ApartmentOutlined />
            <span>{t('Organizations')}</span>
          </Link>
        )
      }
    : null;

  const misc = checkPermission(
    [
      'MessageManagement.view_sms',
      'MessageManagement.view_smsendpoint',
      'MessageManagement.view_email'
    ],
    permissionCheckRule.ANY
  )
    ? {
        key: '/miscellaneous',
        label: (
          <span>
            <ReconciliationOutlined />
            <span>{t('Miscellaneous')}</span>
          </span>
        ),
        children: [
          checkPermission('MessageManagement.view_email')
            ? {
                key: '/messages/email',
                label: <Link to="/messages/email">{t('Emails')}</Link>
              }
            : null,
          checkPermission('MessageManagement.view_sms')
            ? {
                key: '/messages/sms',
                disabled: true,
                label: <Link to="/messages/sms">{t('SMS Messages')}</Link>
              }
            : null,
          checkPermission('MessageManagement.view_smsendpoint')
            ? {
                key: '/messages/sms/endpoint',
                disabled: true,
                label: (
                  <Link to="/messages/sms/endpoint">
                    {t('SMS API Endpoints')}
                  </Link>
                )
              }
            : null
        ]
      }
    : null;

  const styles = {
    '@media print ': {
      display: 'none'
    }
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={navigation.broken ? 0 : 80}
      onBreakpoint={(broken) => {
        dispatch(setNavigationBroken(broken));
      }}
      onCollapse={(collapsed, type) => {
        if (type === 'responsive') {
          //return;
        }
        dispatch(setNavigationCollapsed(collapsed));
      }}
      collapsible
      trigger={navigation.broken ? null : undefined}
      theme="dark"
      style={styles}
      {...collapsed}
    >
      <Menu
        key="main-navigation"
        selectedKeys={[location.pathname]}
        theme="dark"
        onClick={() => {
          if (navigation.broken && !navigation.collapsed) {
            dispatch(setNavigationCollapsed(true));
          }
        }}
        mode="inline"
        items={[
          header,
          home,
          //map,
          stock,
          projects,
          purchasers,
          wood,
          equipment,
          //users,
          organization,
          misc,
          ...(navigation.broken
            ? [
                {
                  type: 'divider' as const,
                  style: { borderColor: token.colorPrimaryBorder }
                },
                ...headerItems
              ]
            : [])
        ]}
      />
    </Sider>
  );
}

export default Navigation;
