import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '../../Store';
import { useSelector } from 'react-redux';
import { Organization } from '../../services/openapi';

const loggingIn = (state: RootState) => state.user.loggingIn;
export const selectLoggingIn = createSelector([loggingIn], (loggingIn) => {
  return loggingIn;
});

const user = (state: RootState) => state.user.user;
export const get_user = createSelector([user], (user) => {
  return user;
});

export const is_authenticated = createSelector([user], (user) => {
  return user && user.username != null;
});

const permissions = (state: RootState) => user(state)?.permissions || [];
export const check_permission = createSelector(
  [permissions],
  (permissions: string[]) => {
    return (permission: string) => {
      if (!is_authenticated) {
        return false;
      }
      return (permissions || []).indexOf(permission) !== -1;
    };
  }
);

export enum permissionCheckRule {
  ALL,
  ANY
}
export function usePermissionCheck() {
  const perms = useAppSelector(permissions);
  return (
    permissions: string | string[],
    rule: permissionCheckRule = permissionCheckRule.ALL
  ) => {
    permissions = Array.isArray(permissions) ? permissions : [permissions];
    switch (rule) {
      case permissionCheckRule.ANY:
        return permissions.some(permission => (perms || []).indexOf(permission) !== -1)
      case permissionCheckRule.ALL:
      default:
        return permissions.every(permission => (perms || []).indexOf(permission) !== -1)
    }
  };
}

const organizationUsers = (state: RootState) =>
  user(state)?.companies_organizationuser || [];
export const selectOrganizationUsers = createSelector(
  [organizationUsers],
  (organizationUsers) => {
    return organizationUsers;
  }
);

export const selectForestCompanies = createSelector(
  [organizationUsers],
  (organizationUsers) => {
    let forestCompanies: Organization[] = [];
    let forestCompanyIDs: number[] = [];
    for (let organizationUser of organizationUsers) {
      if (!forestCompanyIDs.includes(organizationUser.organization.id)) {
        forestCompanies.push(organizationUser.organization);
        forestCompanyIDs.push(organizationUser.organization.id);
      }
    }
    return forestCompanies;
  }
);
