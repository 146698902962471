import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelablePromise,
  PatchedProjectRequest,
  Project,
  ProjectRequest,
  ProjectsService,
  PaginatedProjectList
} from '../../services/api';
import { AppDispatch } from '../../Store';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

export type ProjectsSliceState = {
  projects: Project[];
  projects_updating: boolean;
  stock: { [key: number]: any };
};

const initialState: ProjectsSliceState = {
  projects: [],
  projects_updating: false,
  stock: {}
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    retrieveProjectListStarted(state) {
      state.projects_updating = true;
    },
    retrieveProjectListSuccess(
      state,
      action: PayloadAction<PaginatedProjectList>
    ) {
      state.projects_updating = false;
      const payload = action.payload;
      state.projects = payload.results || [];

      for (let i = 0; i < state.projects.length; i++) {
        // @ts-ignore
        if (state.projects[i].stock) {
          // @ts-ignore
          state.stock[state.projects[i].id] = state.projects[i].stock;
        }
        // @ts-ignore
        delete state.projects[i].stock;
      }
    },
    retrieveProjectListFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.projects_updating = false;
    },
    retrieveProjectSuccess(state, action: PayloadAction<Project>) {
      state.projects_updating = false;
      let project = action.payload;
      state.projects = state.projects.map((t) => {
        return t.id == project.id ? project : t;
      });
      if (!state.projects.some((p) => p.id == project.id)) {
        state.projects.push(project);
      }

      // @ts-ignore
      if (project.stock) {
        // @ts-ignore
        state.stock[project.id] = project.stock;
      }
      // @ts-ignore
      delete project.stock;
    },
    retrieveProjectFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.projects_updating = false;
    },
    deleteProjectSuccess(state, action: PayloadAction<number>) {
      state.projects = state.projects.filter((t) => t.id !== action.payload);
      delete state.stock[action.payload];
    }
  }
});

export const {
  retrieveProjectListStarted,
  retrieveProjectListSuccess,
  retrieveProjectListFailed,
  deleteProjectSuccess
} = projectsSlice.actions;
export const { retrieveProjectSuccess, retrieveProjectFailed } =
  projectsSlice.actions;
export default projectsSlice.reducer;

export const retrieveProjectListStock = () => async (dispatch: AppDispatch) => {
  dispatch(retrieveProjectList(true));
};

let retrieveProjectsList: CancelablePromise<PaginatedProjectList>;
export const retrieveProjectList =
  (stock: boolean = false) =>
  async (dispatch: AppDispatch) => {
    if (retrieveProjectsList) {
      retrieveProjectsList.cancel();
    }
    dispatch(retrieveProjectListStarted());

    retrieveProjectsList = ProjectsService.projectsList({
      stock: stock ? true : undefined,
      pageSize: 10000,
      state: [200, 300]
    });

    return retrieveProjectsList
      .then((data) => {
        dispatch(retrieveProjectListSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve projects!",
          dispatch,
          retrieveProjectListFailed
        );
      });
  };

export const retrieveProject =
  (id: number) => async (dispatch: AppDispatch) => {
    return ProjectsService.projectsRetrieve({ id: id })
      .then((data) => {
        dispatch(retrieveProjectSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve project!",
          dispatch,
          retrieveProjectFailed
        );
      });
  };

export const updateProject =
  (id: number, data: PatchedProjectRequest) =>
  async (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
      ProjectsService.projectsPartialUpdate({
        id: id,
        requestBody: data
      })
        .then((data) => {
          dispatch(retrieveProjectSuccess(data));
          resolve(data);
        })
        .catch((ex) => {
          handleAPIError(ex, "Couldn't update project!", dispatch);
          reject(ex);
        });
    });
  };
export const createProject =
  (data: ProjectRequest) => async (dispatch: AppDispatch) => {
    return ProjectsService.projectsCreate({
      requestBody: data
    })
      .then((data) => {
        dispatch(retrieveProjectSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(ex, "Couldn't create project!", dispatch);
      });
  };

export const deleteProject = (id: number) => async (dispatch: AppDispatch) => {
  return ProjectsService.projectsDestroy({ id })
    .then(function (project) {
      dispatch(deleteProjectSuccess(id));
    })
    .catch(function (ex) {
      handleAPIError(ex, 'Could not delete transaction!', dispatch);
    });
};
