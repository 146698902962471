/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WoodType } from '../models/WoodType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WoodtypesService {

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns WoodType
     * @throws ApiError
     */
    public static woodtypesList(): CancelablePromise<Array<WoodType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woodtypes/',
        });
    }

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns WoodType
     * @throws ApiError
     */
    public static woodtypesRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<WoodType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woodtypes/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
