import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import LogRocket from 'logrocket';
import {AuthService, UserAndPermissions, UsersService} from "../../services/openapi";
import {createNotification} from "../UI/UISlice";
import {AppDispatch} from "../../Store";
import handleAPIError, {APIErrorMessage} from "../../services/api/handleAPIError";

type UserSliceState = {
    user?: UserAndPermissions
    updating: boolean,
    loggingIn: boolean,
    loginMessage?: string,
}

const initialUserState: UserSliceState = {
    updating: false,
    loggingIn: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        retrieveUserStarted(state) {
            state.updating = true;
        },
        retrieveUserSuccess(state, action: PayloadAction<UserAndPermissions>) {
            state.updating = false;
            state.user = action.payload;
        },
        retrieveUserFailed(state, action: PayloadAction<APIErrorMessage>){
            state.updating = false;
        },
        loginStart(state){
            state.loggingIn = true;
            state.loginMessage = undefined;
        },
        loginSuccess(state, action: PayloadAction<UserAndPermissions>) {
            state.loggingIn = false;
            state.loginMessage = undefined;
            state.updating = false;
            state.user = action.payload;
        },
        loginFailed(state, action: PayloadAction<APIErrorMessage>){
            state.loggingIn = false;
            state.loginMessage = action.payload.message;
            state.user = undefined;
        },
        logout(state) {
            state.user = undefined;
            state.loggingIn = false;
            state.loginMessage = undefined;
        }
    }
})


export const {retrieveUserStarted, retrieveUserSuccess, retrieveUserFailed, logout} = userSlice.actions
export const {loginStart, loginSuccess, loginFailed} = userSlice.actions
export default userSlice.reducer

export const retrieveUser = () => async (dispatch:AppDispatch) => {
    dispatch(retrieveUserStarted());
    UsersService.usersMeRetrieve().then((user) => {
        LogRocket.identify(user.username, {
            name: user.username,
            firstName: user.first_name || "",
            lastName: user.last_name || "",
            email: user.email || "",
        });
        dispatch(retrieveUserSuccess(user));
    }).catch((ex) => {
        handleAPIError(ex, "Could not retrieve user details!", dispatch, retrieveUserFailed)
    })
}

export const logoutUser = (onComplete = () => {
}) => async (dispatch:AppDispatch) => {
    AuthService.authSessionLogoutRetrieve().then(() => {
        dispatch(logout());
        onComplete();
    }).catch((ex) => {
        handleAPIError(ex, "Could not logout!", dispatch)
    })
}

export const loginUser = (username: string, password: string) => async (dispatch:AppDispatch) => {
    dispatch(loginStart());
    AuthService.authSessionCreate({
        requestBody: {
            username: username,
            password: password,
        }
    }).then((user) => {
        dispatch(createNotification({
            type: "success",
            title: "Login successful",
            message: ``
        }))
        dispatch(loginSuccess(user));
        dispatch(retrieveUser());
    }).catch((ex) => {
        handleAPIError(ex, "Could not login!", dispatch, loginFailed)
    })
}
