import {Navigate} from "react-router-dom";
import {loginUser} from "../userSlice";
import {useAppDispatch} from "../../../Store";
import React from 'react';
import {Form, Input, Button, Row, Col,} from 'antd';
import {useSelector} from "react-redux";
import {is_authenticated, selectLoggingIn} from "../userSelectors";
import { Typography } from 'antd';
import {useTranslation} from "react-i18next";

const { Title } = Typography;

function LoginPage() {
    let dispatch = useAppDispatch();
    let loggedin = useSelector(is_authenticated);
    let loggingIn = useSelector(selectLoggingIn);
    let {t} = useTranslation();

    const onFinish = (values: any) => {
        dispatch(loginUser(values.username, values.password,))
    };

    const onFinishFailed = (errorInfo: any) => {
    };

    if (loggedin) {
        return <Navigate to={'/'}/>
    }

    return (
      <Row justify="center">
        <Col
          xs={24}
          sm={20}
          md={16}
          lg={12}
          xl={8}
          style={{ backgroundColor: 'white', padding: 30, marginBottom: 40 }}
        >
          <Title>{t('Login')}</Title>
          <Form
            name="login"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label={t('Username')}
              name="username"
              rules={[
                {
                  required: true,
                  message: t('Please input your username!') as string
                }
              ]}
            >
              <Input
                type="text"
                name="username"
                autoFocus={true}
                autoCapitalize="none"
                autoComplete="username"
              />
            </Form.Item>

            <Form.Item
              label={t('Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Please input your password!') as string
                }
              ]}
            >
              <Input.Password autoComplete="current-password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loggingIn}
                block={true}
              >
                {loggingIn ? t('Logging in...') : t('Log in')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
}

export default LoginPage
