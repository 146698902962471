import React, { ErrorInfo, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Result } from 'antd';

import { detect } from 'detect-browser';
const browser = detect();

type ErrorProps = {
  children?: ReactNode;
  t: (arg: string) => string;
};

type ErrorState = {
  error: Error | null;
  info: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: any) {
    super(props);
    // Add some default error states
    this.state = {
      error: null,
      info: null
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Something happened to one of my children.
    // Add error to state
    this.setState({
      error: error,
      info: info
    });
  }

  render() {
    let browserInfo = <>'Browser not detected'</>;
    if (browser) {
      browserInfo = (
        <>
          Browser: {browser.name}
          <br />
          Version: {browser.version}
          <br />
          OS: {browser.os}
          <br />
        </>
      );
    }

    if (this.state.error) {
      // Some error was thrown. Let's display something helpful to the user
      return (
        <Result
          status="error"
          title={this.props.t('Something went wrong')}
          subTitle={this.state.error && this.state.error.toString()}
          extra={[
            <Button
              key="tryAgain"
              type="primary"
              onClick={() => {
                this.setState({
                  error: null,
                  info: null
                });
              }}
            >
              {this.props.t('Try again')}
            </Button>,
            <Button
              key="reload"
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              {this.props.t('Reload')}
            </Button>
          ]}
        >
          <div key="container" className="desc">
            {browserInfo}

            <details open key="details">
              <h4>Stacktrace:</h4>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.info?.componentStack || ''}
              </div>
            </details>
          </div>
        </Result>
      );
    }
    // No errors were thrown. As you were.
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
