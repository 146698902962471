import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelablePromise,
  EquipmentAndLocation,
  EquipmentService
} from '../../services/openapi';
import { AppDispatch } from '../../Store';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

type EquipmentsSliceState = {
  equipment: { [key: number]: EquipmentAndLocation };
  equipment_updating: boolean;
};

const initialState: EquipmentsSliceState = {
  equipment: {},
  equipment_updating: false
};
const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: initialState,
  reducers: {
    retrieveEquipmentListStarted(state) {
      state.equipment_updating = true;
    },
    retrieveEquipmentListSuccess(
      state,
      action: PayloadAction<EquipmentAndLocation[]>
    ) {
      state.equipment_updating = false;
      state.equipment = {};
      for (let key in action.payload) {
        let equipment = action.payload[key];
        state.equipment[equipment.id] = equipment;
      }
    },
    retrieveEquipmentListFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.equipment_updating = false;
    },
    retrieveEquipmentSuccess(
      state,
      action: PayloadAction<EquipmentAndLocation>
    ) {
      let equipment = action.payload;
      state.equipment[equipment.id] = equipment;
    },
    retrieveEquipmentFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.equipment_updating = false;
    }
  }
});

export const {
  retrieveEquipmentListStarted,
  retrieveEquipmentListSuccess,
  retrieveEquipmentListFailed
} = equipmentSlice.actions;
export const { retrieveEquipmentSuccess, retrieveEquipmentFailed } =
  equipmentSlice.actions;
export default equipmentSlice.reducer;

let retrieveEquipmentsList: CancelablePromise<EquipmentAndLocation[]>;
export const retrieveEquipmentList = () => async (dispatch: AppDispatch) => {
  if (retrieveEquipmentsList) {
    retrieveEquipmentsList.cancel();
  }
  dispatch(retrieveEquipmentListStarted());

  retrieveEquipmentsList = EquipmentService.equipmentList();

  retrieveEquipmentsList
    .then((data) => {
      dispatch(retrieveEquipmentListSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve equipments!",
        dispatch,
        retrieveEquipmentListFailed
      );
    });
};

export const retrieveEquipment =
  (id: number) => async (dispatch: AppDispatch) => {
    EquipmentService.equipmentRetrieve({ id: id.toString() })
      .then((data) => {
        dispatch(retrieveEquipmentSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve equipment!",
          dispatch,
          retrieveEquipmentFailed
        );
      });
  };
