/**
 * Created by mathi on 12-1-2018.
 */
import React, {Component} from 'react'

import connect from '../../../../libs/connect'

import './Chat.css';

const mapStateToProps = function(state, ownProps) {
  return {}
}


class DriverItem extends Component{
    render(){
        const lastMessage = this.props.driver.messages[0] || {};
        const isNew = (lastMessage.status !== 'Read' && lastMessage.user === null)
        return (
            <li className="list-group-item" onClick={() => {this.props.setOpenChat(this.props.driver.id);}}>
                <b>{this.props.driver.name}</b><br />
                {lastMessage.content} {isNew?<span className="label label-success pull-right">{this.props.trans('New')}</span>:null}
            </li>
        );
    }
}

export default connect(mapStateToProps)(DriverItem);
