import { useNavigate } from "react-router-dom";
import {logoutUser} from "../userSlice";
import {useAppDispatch} from "../../../Store";
import {useEffect} from "react";

function LogoutPage() {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logoutUser(
        () => {navigate('/')}
    ));
  });

  return <h1>Logging out</h1>;
}

export default LogoutPage
