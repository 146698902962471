/**
 * Created by mathi on 12-1-2018.
 */
import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

function MyFooter() {
  const { t } = useTranslation(['layout']);
  return (
    <Footer style={{ textAlign: 'center' }}>
      &copy; {t('layout:::footer')}
    </Footer>
  );
}

export default MyFooter;
