import React from 'react';
import { Breadcrumb, Card, Col, Layout, Menu, Row, Typography } from 'antd';
const { Content } = Layout;
const { Title, Link } = Typography;
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

export interface PageProps extends React.PropsWithChildren {
  title: string;
  showBackButton?: boolean;
  extraContent?: React.ReactNode;
}

function Page({
  title,
  children,
  extraContent,
  showBackButton = false
}: PageProps) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const backButton = showBackButton ? (
    <Link onClick={goBack} style={{ marginRight: 20 }}>
      <ArrowLeftOutlined style={{ fontSize: 30 }} />
    </Link>
  ) : null;
  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <Title style={{ marginLeft: 10, marginTop: 5, marginBottom: 0 }}>
          {backButton}
          {title}
        </Title>
      </Col>
      <Col xs={24}>{extraContent}</Col>
      <Col xs={24}>
        <Card>{children}</Card>
      </Col>
    </Row>
  );
}

export default Page;
