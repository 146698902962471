import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import detector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import locale_nl from 'dayjs/locale/nl';
import locale_de from 'dayjs/locale/de';
import { enumKeys } from '../services/api';

export enum Languages {
  nl = 'Nederlands',
  de = 'Deutsch',
  en = 'English'
}

i18n
  .use(detector)
  .use(initReactI18next)
  .use(i18nextPlugin)
  .use({
    type: 'backend',
    read<Namespace extends keyof any>(
      language: any,
      namespace: Namespace,
      callback: (
        errorValue: unknown,
        translations: null | any[Namespace]
      ) => void
    ) {
      import(`./locales/${language}/${String(namespace)}.json`)
        .then((resources) => {
          callback(null, resources.default);
        })
        .catch((error) => {
          callback(error, null);
        });
    }
  })
  .init({
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    supportedLngs: enumKeys(Languages),
    returnEmptyString: false,
    ns: ['translation'],
    defaultNS: 'translation',
    returnNull: false,
    nsSeparator: ':::',
    keySeparator: '::',
    fallbackLng: 'en'
  });
i18n.on('languageChanged', function (lng) {
  switch (lng) {
    case 'nl':
      dayjs.locale(locale_nl);
      break;
    case 'de':
      dayjs.locale(locale_de);
      break;
    default:
      dayjs.locale('en');
      break;
  }
  document.documentElement.setAttribute('lang', lng);

  return true;
});

declare global {
  interface Window {
    dayjs: typeof dayjs;
    i18n: typeof i18n;
  }
}

window.dayjs = dayjs;
window.i18n = i18n;
export default i18n;
