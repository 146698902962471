/**
 * Created by mathi on 12-1-2018.
 */
import React from 'react';
import {
  setSidebarComponent,
  setSidebarVisible
} from '../../features/UI/UISlice';
import { setNavigationCollapsed } from '../../features/UI/UISlice';
import { NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Layout, Menu, Tag } from 'antd';
import { get_unread_messages } from '../../features/messages/messagesSelectors';
import { get_navigation } from '../../features/UI/UISelectors';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TranslationOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import version from '../../version';
import { useAppDispatch, useAppSelector } from '../../Store';
import {
  get_user,
  usePermissionCheck
} from '../../features/user/userSelectors';
import i18n, { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { UserAndPermissions } from '../../services/openapi';
import { Languages } from '../../i18n/config';
import { enumKeys } from '../../services/api';
const { Header } = Layout;

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export function getHeaderItems() {
  const dispatch = useAppDispatch();
  const checkPermission = usePermissionCheck();
  const { t } = useTranslation();
  const user = useAppSelector(get_user);

  const currentLanguageCode = i18n.language as keyof typeof Languages;

  const languageSelector = {
    key: 'language',
    label: (
      <span>
        <TranslationOutlined />
        <span>{Languages[currentLanguageCode] || 'Language'}</span>
      </span>
    ),
    children: enumKeys(Languages).map((languageKey) => {
      return {
        key: languageKey,
        onClick: () => {
          i18n.changeLanguage(languageKey);
        },
        label: Languages[languageKey]
      };
    })
  };

  const userInfo = user
    ? {
        key: 'user',
        label: (
          <span>
            <UserOutlined />
            <span>
              <strong>{user.username}</strong>
            </span>
          </span>
        ),
        children: [
          {
            key: '/user/logout',
            label: <Link to="/user/logout">{t('translation:::Logout')}</Link>
          },
          {
            key: 'forceReload',
            label: (
              <span
                onClick={() => {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                      for (let registration of registrations) {
                        registration.unregister();
                      }
                    })
                    .then(() => {
                      location.reload();
                    });
                }}
              >
                {t('Force reload')}
              </span>
            )
          }
        ]
      }
    : null;

  const messageBadge = checkPermission('LisaApi.view_message')
    ? {
        key: 'messages',
        onClick: () => {
          dispatch(setSidebarComponent({ component: 'ChatOverview' }));
          dispatch(setSidebarVisible(true));
        },
        label: (
          <Badge count={0 /*unreadMessages*/}>
            <NotificationOutlined style={{ color: 'white' }} />
          </Badge>
        )
      }
    : null;

  return [languageSelector, userInfo];
}

function Navigation() {
  const checkPermission = usePermissionCheck();

  const navigation = useAppSelector(get_navigation);
  const user = useAppSelector(get_user);
  const unreadMessages = useAppSelector(get_unread_messages);
  const headerItems = getHeaderItems();

  const dispatch = useAppDispatch();

  const trigger = navigation.broken
    ? {
        key: 'navigation_collapsed',
        label: navigation.collapsed ? (
          <MenuUnfoldOutlined
            style={{ fontSize: '18px', marginTop: '15px' }}
            onClick={() => {
              dispatch(setNavigationCollapsed(false));
            }}
          />
        ) : (
          <MenuFoldOutlined
            style={{ fontSize: '18px', marginTop: '15px' }}
            onClick={() => {
              dispatch(setNavigationCollapsed(true));
            }}
          />
        )
      }
    : null;
  const versionBadge = {
    style: { marginLeft: 'auto' },
    key: 'version',
    label: (
      <a
        href="https://headwayapp.co/timbertracer-updates"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tag color="#87d068" style={{ marginTop: 12 }}>
          {version}
        </Tag>
      </a>
    )
  };

  return (
    <Header style={{ padding: 0, height: '48px' }}>
      <Menu
        mode="horizontal"
        theme="dark"
        selectable={false}
        items={[
          trigger,
          versionBadge,
          ...(navigation.broken ? [] : headerItems)
        ]}
      />
    </Header>
  );
}

export default Navigation;
