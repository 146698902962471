import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../Store';

var drivers = (state: RootState) => state.drivers.drivers;
export const get_drivers = createSelector([drivers], (drivers) => {
  return drivers;
});

export const get_drivers_sorted = createSelector([drivers], (drivers) => {
  return Object.values(drivers).sort((a, b) => {
    return ((a || {}).name || '').localeCompare((b || {}).name || '');
  });
});

export const get_active_drivers = createSelector([drivers], (drivers) => {
  return Object.values(drivers).filter((item) => {
    return (item.locations || []).length > 0;
  });
});

const driver = (state: RootState, id: number) => state.drivers.drivers[id];
export const get_driver_by_id = createSelector([driver], (driver) => {
  return driver || null;
});

const active_driver = (state: RootState) => -1;
//state.drivers.drivers[(state.dashboardState.map.activeDriver || {}).id];

export const get_active_driver = createSelector(
  [active_driver],
  (active_driver) => {
    return active_driver || null;
  }
);
