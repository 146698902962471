import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelablePromise,
  Driver,
  DriverLocation,
  DriversService
} from '../../services/openapi';
import { AppDispatch } from '../../Store';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

type DriversSliceState = {
  drivers: { [key: number]: Driver & { locations?: DriverLocation[] } };
  drivers_updating: boolean;
};

const initialState: DriversSliceState = {
  drivers: {},
  drivers_updating: false
};
const driversSlice = createSlice({
  name: 'drivers',
  initialState: initialState,
  reducers: {
    retrieveDriverListStarted(state) {
      state.drivers_updating = true;
    },
    retrieveDriverListSuccess(state, action: PayloadAction<Driver[]>) {
      state.drivers_updating = false;
      state.drivers = {};
      for (let key in action.payload) {
        let driver = action.payload[key] as Driver;
        state.drivers[driver.id] = driver;
      }
    },
    retrieveDriverListFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.drivers_updating = false;
    },
    retrieveDriverSuccess(state, action: PayloadAction<Driver>) {
      let driver = action.payload;
      state.drivers[driver.id] = driver;
    },
    retrieveDriverFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.drivers_updating = false;
    }
  }
});

export const {
  retrieveDriverListStarted,
  retrieveDriverListSuccess,
  retrieveDriverListFailed
} = driversSlice.actions;
export const { retrieveDriverSuccess, retrieveDriverFailed } =
  driversSlice.actions;
export default driversSlice.reducer;

let retrieveDriversList: CancelablePromise<Driver[]>;
export const retrieveDriverList = () => async (dispatch: AppDispatch) => {
  if (retrieveDriversList) {
    retrieveDriversList.cancel();
  }
  dispatch(retrieveDriverListStarted());

  retrieveDriversList = DriversService.driversList();

  retrieveDriversList
    .then((data) => {
      dispatch(retrieveDriverListSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve drivers!",
        dispatch,
        retrieveDriverListFailed
      );
    });
};

export const retrieveDriver = (id: number) => async (dispatch: AppDispatch) => {
  DriversService.driversRetrieve({ id: id.toString() })
    .then((data) => {
      dispatch(retrieveDriverSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve driver!",
        dispatch,
        retrieveDriverFailed
      );
    });
};
