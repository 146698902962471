import { createSelector } from '@reduxjs/toolkit'

const unread_messages = state => state.messages.unread;
export const get_unread_messages = createSelector(
  [unread_messages],
  (unread_messages) => {
    return unread_messages;
  }
)

const messages = (state) => state.messages.messages;
export const get_messages = createSelector(
  [messages],
  (messages) => {
    return (driverid = null) => {
            if(driverid !== null) {
                return Object.values(messages).filter(message => {
                    return message.driver.id === driverid
                }).sort(function(a,b){
                    if(a.id < b.id){
                        return 1;
                    }else return -1;
                })
            }
            return messages;
    }
  }
)
