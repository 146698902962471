import { createNotification } from '../../features/UI/UISlice';
import { APIErrorMessage } from './handleAPIError';

export default function createAPIErrorNotification(error: APIErrorMessage) {
  return createNotification({
    type: error.type,
    title: error.title,
    message: error.message,
    duration: error.duration
  });
}
