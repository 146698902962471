import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  CancelablePromise,
  PatchedPurchaserRequest,
  Purchaser,
  PurchasersService,
  PurchaserRequest,
  PurchaserStatistics
} from '../../services/api';
import { AppDispatch } from '../../Store';
import handleAPIError, {
  APIErrorMessage
} from '../../services/api/handleAPIError';

type PurchasersSliceState = {
  purchasers: { [key: number]: Purchaser };
  purchasers_updating: boolean;
  statistics: {
    [key: number]: {
      [key: string]: number;
    };
  };
  statistics_updating: boolean;
};

const initialState: PurchasersSliceState = {
  purchasers: {},
  purchasers_updating: false,
  statistics: {},
  statistics_updating: false
};

const purchasersSlice = createSlice({
  name: 'purchasers',
  initialState: initialState,
  reducers: {
    retrievePurchaserListStarted(state) {
      state.purchasers_updating = true;
    },
    retrievePurchaserListSuccess(state, action: PayloadAction<Purchaser[]>) {
      state.purchasers_updating = false;
      state.purchasers = {};
      for (let key in action.payload) {
        let purchaser = action.payload[key];
        state.purchasers[purchaser.id] = purchaser;
      }
    },
    retrievePurchaserListFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.purchasers_updating = false;
    },
    retrievePurchaserSuccess(state, action: PayloadAction<Purchaser>) {
      state.purchasers_updating = false;
      let purchaser = action.payload;
      state.purchasers[purchaser.id] = purchaser;
    },
    retrievePurchaserFailed(state, action: PayloadAction<APIErrorMessage>) {
      state.purchasers_updating = false;
    },
    retrievePurchaserStatisticsStarted(state) {
      state.statistics_updating = true;
    },
    retrievePurchaserStatisticsSuccess(
      state,
      action: PayloadAction<PurchaserStatistics[]>
    ) {
      state.statistics_updating = false;
      state.statistics = {};
      action.payload.map((stat) => {
        if (!state.statistics[stat.purchaser_id]) {
          state.statistics[stat.purchaser_id] = {
            in_transit: 0
          };
        }
        let timestamp = stat.date_to_count
          ? dayjs(stat.date_to_count, 'YYYY-MM-DD').valueOf()
          : 'in_transit';
        state.statistics[stat.purchaser_id][timestamp] = stat.nr_transactions;
        return null;
      });
    },
    retrievePurchaserStatisticsFailed(
      state,
      action: PayloadAction<APIErrorMessage>
    ) {
      state.statistics_updating = false;
    },
    deletePurchaserSuccess(state, action: PayloadAction<number>) {
      delete state.purchasers[action.payload];
    }
  }
});

export const {
  retrievePurchaserListStarted,
  retrievePurchaserListSuccess,
  retrievePurchaserListFailed,
  retrievePurchaserSuccess,
  retrievePurchaserFailed,
  retrievePurchaserStatisticsStarted,
  retrievePurchaserStatisticsSuccess,
  retrievePurchaserStatisticsFailed,
  deletePurchaserSuccess
} = purchasersSlice.actions;
export default purchasersSlice.reducer;

let retrievePurchasersList: CancelablePromise<Purchaser[]>;
export const retrievePurchaserList = () => async (dispatch: AppDispatch) => {
  retrievePurchasersList && retrievePurchasersList.cancel();
  dispatch(retrievePurchaserListStarted());
  PurchasersService.purchasersList({})
    .then((data) => {
      dispatch(retrievePurchaserListSuccess(data));
    })
    .catch((ex) => {
      handleAPIError(
        ex,
        "Couldn't retrieve purchasers!",
        dispatch,
        retrievePurchaserListFailed
      );
    });
};

export const retrievePurchaser =
  (id: number) => async (dispatch: AppDispatch) => {
    PurchasersService.purchasersRetrieve({ id: id.toString() })
      .then((data) => {
        dispatch(retrievePurchaserSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve purchaser!",
          dispatch,
          retrievePurchaserFailed
        );
      });
  };

export const updatePurchaser =
  (id: number, data: PatchedPurchaserRequest) =>
  async (dispatch: AppDispatch) => {
    PurchasersService.purchasersPartialUpdate({
      id: id.toString(),
      requestBody: data
    })
      .then((data) => {
        dispatch(retrievePurchaserSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't update purchaser!",
          dispatch,
          retrievePurchaserFailed
        );
      });
  };
export const createPurchaser =
  (data: PurchaserRequest) => async (dispatch: AppDispatch) => {
    PurchasersService.purchasersCreate({
      requestBody: data
    })
      .then((data) => {
        dispatch(retrievePurchaserSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't create purchaser!",
          dispatch,
          retrievePurchaserFailed
        );
      });
  };

export const deletePurchaser =
  (id: number) => async (dispatch: AppDispatch) => {
    PurchasersService.purchasersDestroy({
      id: id.toString()
    })
      .then((data) => {
        dispatch(deletePurchaserSuccess(id));
      })
      .catch((ex) => {
        handleAPIError(ex, "Couldn't delete purchaser!", dispatch);
      });
  };
let retrievePurchaserStatisticsCancel: CancelablePromise<PurchaserStatistics[]>;
export const retrievePurchaserStatistics =
  (rangeStart: string, rangeEnd: string) => async (dispatch: AppDispatch) => {
    retrievePurchasersList && retrievePurchasersList.cancel();
    retrievePurchaserStatisticsCancel &&
      retrievePurchaserStatisticsCancel.cancel();

    dispatch(retrievePurchaserStatisticsStarted());
    retrievePurchaserStatisticsCancel =
      PurchasersService.purchasersStatisticsList({
        rangeStart: rangeStart,
        rangeEnd: rangeEnd
      });

    retrievePurchaserStatisticsCancel
      .then((data) => {
        dispatch(retrievePurchaserStatisticsSuccess(data));
      })
      .catch((ex) => {
        handleAPIError(
          ex,
          "Couldn't retrieve purchasers!",
          dispatch,
          retrievePurchaserStatisticsFailed
        );
      });
  };
