import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export type NotificationTypes = 'info' | 'success' | 'warning' | 'error';
export type NotificationLocations =
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight';
type Notification = {
  id: string;
  type: NotificationTypes;
  title: string;
  message: string;
  location: NotificationLocations;
  duration?: number;
};
type NotificationCreate = Partial<Notification>;

type UISliceState = {
  navigation: {
    collapsed: boolean;
    broken: boolean;
  };
  sidebar: {
    visible: boolean;
    component?: string;
  };
  notifications: Notification[];
};

const initialState: UISliceState = {
  navigation: {
    collapsed: false,
    broken: false
  },
  sidebar: {
    visible: false,
    component: undefined
  },
  notifications: []
};

const UISlice = createSlice({
  name: 'UI',
  initialState: initialState,
  reducers: {
    setNavigationCollapsed(state, action: PayloadAction<boolean>) {
      state.navigation.collapsed = action.payload;
    },
    setNavigationBroken(state, action: PayloadAction<boolean>) {
      state.navigation.broken = action.payload;
    },
    setSidebarVisible(state, action: PayloadAction<boolean>) {
      state.sidebar.visible = action.payload;
    },
    setSidebarComponent(state, action) {
      state.sidebar.component = action.payload.component;
    },
    createNotification: (state, action: PayloadAction<NotificationCreate>) => {
      const notification: Notification = {
        id: uuidv4(),
        type: 'info',
        title: '',
        message: '',
        location: 'bottomRight',
        ...action.payload
      };
      state.notifications.push(notification);
    },
    dismissNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    dismissNotifications: (state) => {
      state.notifications = [];
    }
  }
});

const { actions, reducer } = UISlice;
export const { setNavigationCollapsed, setNavigationBroken } = actions;
export const { setSidebarVisible, setSidebarComponent } = actions;
export const { createNotification, dismissNotification, dismissNotifications } =
  UISlice.actions;
export default reducer;
