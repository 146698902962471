/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Driver } from '../models/Driver';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DriversService {

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns Driver
     * @throws ApiError
     */
    public static driversList(): CancelablePromise<Array<Driver>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/drivers/',
        });
    }

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns Driver
     * @throws ApiError
     */
    public static driversRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Driver> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/drivers/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
