/**
 * Created by mathi on 12-1-2018.
 */
import React from 'react';
import { setSidebarVisible } from '../../features/UI/UISlice';
import { get_sidebar } from '../../features/UI/UISelectors';
import './Sidebar.css';
import { Drawer } from 'antd';
import { useAppDispatch, useAppSelector } from '../../Store';

function Sidebar({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const sidebar = useAppSelector(get_sidebar);

  const hideSidebar = () => {
    dispatch(setSidebarVisible(false));
  };
  return (
    <Drawer open={sidebar.visible} onClose={hideSidebar}>
      {children}
    </Drawer>
  );
}

export default Sidebar;
