import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './index.less';
import './App.less';
import i18n from './i18n/config';
import store, { useAppDispatch } from './Store';
import { retrieveUser } from './features/user/userSlice';
import {
  retrieveUnits,
  retrieveWoodTypeList
} from './features/assortments/assortmentsSlice';
import { retrieveMessageList } from './features/messages/messagesSlice';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistStore } from 'redux-persist';
import Router from './Router';
import Navigation from './Layout/Navigation/Navigation';
import Header from './Layout/Header/Header';
import Footer from './Layout/Footer/Footer';
import { get_navigation, get_sidebar } from './features/UI/UISelectors';
import ChatOverview from './features/messages/components/chat/ChatOverview';
import { Layout } from 'antd';
import Sidebar from './pages/Components/Sidebar';
import { setNavigationCollapsed } from './features/UI/UISlice';
import LogRocket from 'logrocket';
import version from './version';
import NotificationSystem from './features/UI/components/NotificationSystem';
import { is_authenticated } from './features/user/userSelectors';
import { I18nextProvider } from 'react-i18next';
import ServiceworkerUpdater from './features/UI/components/ServiceworkerUpdater';

LogRocket.init('26udlf/timbertracer', {
  release: version
});

const { Content } = Layout;

let persistor = persistStore(store);
const SidebarComponents: { [key: string]: any } = {
  ChatOverview: ChatOverview
};

function App() {
  const dispatch = useAppDispatch();
  const sidebar = useSelector(get_sidebar);
  const navigation = useSelector(get_navigation);
  const authenticated = useSelector(is_authenticated);
  const SidebarComponent = sidebar.component
    ? SidebarComponents[sidebar.component]
    : null;

  useEffect(() => {
    dispatch(retrieveUser());
    let userUpdater = setInterval(() => dispatch(retrieveUser()), 60 * 1000);
    return () => {
      clearInterval(userUpdater);
    };
  }, [dispatch]);

  useEffect(() => {
    if (authenticated) {
      dispatch(retrieveWoodTypeList());
      dispatch(retrieveUnits());
      const interval = setInterval(
        () => dispatch(retrieveMessageList()),
        30 * 1000
      );
      const timeout = setTimeout(
        () => dispatch(retrieveMessageList()),
        3 * 1000
      );

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [authenticated, dispatch]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Suspense fallback={'Loading'}>
        <NotificationSystem />
        <ServiceworkerUpdater />
        <Navigation />
        <Layout>
          <Header />
          <Content
            style={{
              margin: '4px',
              marginTop: 20,
              // marginLeft: (navigation.broken) ? 0 : (navigation.collapsed) ? 80 : 204,
              padding: 2,
              minHeight: 280,
              overflowX: 'auto'
            }}
            onClick={() => {
              if (navigation.broken && !navigation.collapsed) {
                dispatch(setNavigationCollapsed(true));
              }
            }}
          >
            <Router />
            <Footer />
          </Content>
        </Layout>

        <Sidebar>
          {SidebarComponent !== null ? <SidebarComponent /> : null}
        </Sidebar>
      </Suspense>
    </Layout>
  );
}

export default function renderAppInElement() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
}
