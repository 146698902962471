import { createSelector } from '@reduxjs/toolkit'
import {RootState} from "../../Store";

const navigation = (state:RootState) => state.UI.navigation;
export const get_navigation = createSelector(
  [navigation],
  (navigation) => {
    return navigation;
  }
)

const sidebar = (state:RootState) => state.UI.sidebar;
export const get_sidebar = createSelector(
  [sidebar],
  (sidebar) => {
    return sidebar;
  }
)


const notifications = (state:RootState) => state.UI.notifications;
export const selectAllNotifications = createSelector(
  [notifications],
  (notifications) => {
    return notifications;
  }
)
