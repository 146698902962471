/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedTransactionList } from '../models/PaginatedTransactionList';
import type { PatchedTransactionRequest } from '../models/PatchedTransactionRequest';
import type { Transaction } from '../models/Transaction';
import type { TransactionRequest } from '../models/TransactionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionsService {

    /**
     * API endpoint to access transactions
     * @returns PaginatedTransactionList
     * @throws ApiError
     */
    public static transactionsList({
        assortment,
        cmr,
        dateMax,
        dateMin,
        driver,
        ordering,
        page,
        pageSize,
        project,
        purchaser,
        state,
    }: {
        /**
         * Filter by assortment id
         */
        assortment?: Array<number>,
        /**
         * Search by cmr
         */
        cmr?: string,
        /**
         * Filter to date
         */
        dateMax?: string,
        /**
         * Filter from date
         */
        dateMin?: string,
        /**
         * Filter by driver id
         */
        driver?: Array<number>,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * Filter by project id
         */
        project?: Array<number>,
        /**
         * Filter by purchaser id
         */
        purchaser?: Array<number>,
        /**
         * Filter by state
         *
         * * `1` - Planned
         * * `2` - Loading
         * * `3` - Loaded
         * * `4` - Unloading
         * * `5` - Unloaded
         * * `6` - Confirmed
         * * `7` - Invoiced
         */
        state?: Array<1 | 2 | 3 | 4 | 5 | 6 | 7>,
    }): CancelablePromise<PaginatedTransactionList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transactions/',
            query: {
                'assortment': assortment,
                'cmr': cmr,
                'date_max': dateMax,
                'date_min': dateMin,
                'driver': driver,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'project': project,
                'purchaser': purchaser,
                'state': state,
            },
        });
    }

    /**
     * API endpoint to access transactions
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsCreate({
        requestBody,
    }: {
        requestBody: TransactionRequest,
    }): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transactions/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API endpoint to access transactions
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
    }): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transactions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * API endpoint to access transactions
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
        requestBody: TransactionRequest,
    }): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/transactions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API endpoint to access transactions
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
        requestBody?: PatchedTransactionRequest,
    }): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/transactions/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API endpoint to access transactions
     * @returns void
     * @throws ApiError
     */
    public static transactionsDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/transactions/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * API endpoint to retrieve transaction attachments
     * @returns void
     * @throws ApiError
     */
    public static transactionsAttachmentsRetrieve({
        id,
        type,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
        type: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transactions/{id}/attachments/{type}/',
            path: {
                'id': id,
                'type': type,
            },
        });
    }

    /**
     * API endpoint to retrieve transaction attachments
     * @returns Transaction
     * @throws ApiError
     */
    public static transactionsAttachmentsCreate({
        id,
        type,
        formData,
    }: {
        /**
         * A unique integer value identifying this Transaction.
         */
        id: number,
        type: string,
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transactions/{id}/attachments/{type}/',
            path: {
                'id': id,
                'type': type,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
