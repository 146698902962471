/**
 * Created by mathi on 12-1-2018.
 */
import React, {Component} from 'react'

import connect from '../../../../libs/connect'
import './Chat.css';

import ChatMessage from "./ChatMessage";

const mapStateToProps = function(state, ownProps) {
  return {

  }
}

const mapDispatchToProps = function(dispatch, ownProps) {
  return {
      sendMessage: function(driver, content){

      },
      setMessageAsRead: function(message){
      }
  }
}
class Chat extends Component{
    constructor(props){
        super(props);
        this.state = {inputText: ""}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
         this.setState({inputText: event.target.value});
    }

    componentDidMount(){
        const driver = this.props.driver;
         driver.messages.map((message) => {
            if(message.status !== 'Read' && message.user === null){
                this.props.setMessageAsRead(message);
            }
            return "";
        })
    }

    handleSubmit(){
        this.props.sendMessage(this.props.driver.id, this.state.inputText);
        this.setState({inputText: ""});
    }

    render(){
        const driver = this.props.driver;
        return (
            <div className="chat">
                <button onClick={()=>{this.props.setOpenChat(null)}} className="btn btn-default">&lt;&lt; {this.props.trans('Back')}</button>
                <h2>{driver.name}</h2>
                <div className="container-fluid">
                    {driver.messages.map((message) => {
                        return <ChatMessage key={message.id} message={message}/>
                    })}
                </div>
                {this.props.check_permission('LisaApi.add_message')?(
                    <div className="container-fluid">
                        <div className="row">
                           <input type="text"  value={this.state.inputText} onChange={this.handleChange} />
                           <input type="submit" className="btn btn-success" onClick={this.handleSubmit} value={this.props.trans('Send')} />
                        </div>
                    </div>
                ):null}
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Chat);
