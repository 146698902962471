/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenRefreshRequest } from '../models/TokenRefreshRequest';
import type { TokenVerifyRequest } from '../models/TokenVerifyRequest';
import type { UserAndPermissions } from '../models/UserAndPermissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * This function logs in the user and returns
     * and HttpOnly cookie, the `sessionid` cookie
     * @returns UserAndPermissions
     * @throws ApiError
     */
    public static authSessionCreate({
        requestBody,
    }: {
        requestBody: LoginRequest,
    }): CancelablePromise<UserAndPermissions> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/session/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * `login_view` requires that a csrf cookie be set.
     * @returns any No response body
     * @throws ApiError
     */
    public static authSessionCsrfRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/session/csrf/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static authSessionLogoutRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/session/logout/',
        });
    }

    /**
     * API endpoint for token authentication
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static authTokenCreate({
        requestBody,
    }: {
        requestBody: LoginRequest,
    }): CancelablePromise<TokenRefresh> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API endpoint for token authentication
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static authTokenRefreshCreate({
        requestBody,
    }: {
        requestBody: TokenRefreshRequest,
    }): CancelablePromise<TokenRefresh> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/token/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API endpoint for token authentication
     * @returns any No response body
     * @throws ApiError
     */
    public static authTokenVerifyCreate({
        requestBody,
    }: {
        requestBody: TokenVerifyRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/token/verify/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
