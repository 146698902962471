/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EquipmentAndLocation } from '../models/EquipmentAndLocation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EquipmentService {

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns EquipmentAndLocation
     * @throws ApiError
     */
    public static equipmentList(): CancelablePromise<Array<EquipmentAndLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/equipment/',
        });
    }

    /**
     * API endpoint that allows drivers to be viewed.
     * @returns EquipmentAndLocation
     * @throws ApiError
     */
    public static equipmentRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<EquipmentAndLocation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/equipment/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
