/**
 * Created by mathi on 12-1-2018.
 */
import React, {Component} from 'react'

import {get_drivers} from '../../../drivers/driversSelectors';
import {get_messages} from '../../messagesSelectors';
import connect from '../../../../libs/connect'

import './Chat.css';
import DriverItem from "./DriverItem"
import Chat from "./Chat";

const mapStateToProps = function(state, ownProps) {
  return {
            get_messages: get_messages(state),
            drivers: get_drivers(state)
        }
}


class ChatOverview extends Component{
    constructor(props){
        super(props);
        this.state = {openChat: null}
    }
    openChat(driver){
        this.setState({openChat: driver})
    }

    render(){
        if(!this.props.check_permission('LisaApi.view_message')){
            return null;
        }
        var drivers = Object.values(this.props.drivers).filter((driver) => {
            return driver.LISA_active;
        }).map((driver) => {
            driver = Object.assign({}, driver);
            driver.messages = this.props.get_messages(driver.id);
            return driver;
        }).sort((a, b) => {
            if(a.messages.length > 0 && b.messages.length <= 0){
                return -1;
            }else if(a.messages.length <= 0 && b.messages.length > 0){
                return 1;
            }
            else if(a.messages.length <= 0 && b.messages.length <= 0){
                 if(a.name < b.name){
                     return -1;
                 }else{
                     return 1;
                 }
            }
            //Order by messages
            if(a.messages[0].id < b.messages[0].id){
                return 1;
            }else {
                return -1;
            }
        });


        if(this.state.openChat !== null){
            var driver = Object.values(this.props.drivers).find((item) => {
                return item.id === this.state.openChat;
            });
            driver.messages = driver.messages.reverse();
            if(driver !== null) {
                return <Chat driver={driver} setOpenChat={this.openChat.bind(this)}/>
            }
        }

        return (
            <ul className="list-group">
                {drivers.map(driver => <DriverItem key={driver.id} driver={driver} setOpenChat={this.openChat.bind(this)} />)}
            </ul>
        );
    }
}

export default connect(mapStateToProps)(ChatOverview);
