import createAPIErrorNotification from './createAPIErrorNotification';
import { ApiError, CancelError } from '../openapi';
import { NotificationTypes } from '../../features/UI/UISlice';
import { AppDispatch } from '../../Store';
import { Action } from 'redux';
import { logout } from '../../features/user/userSlice';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from '@reduxjs/toolkit';

export type APIErrorMessage = {
  type: NotificationTypes;
  title: string;
  message: string;
  showNotification: boolean;
  duration?: number;
};

type FailedAPIType = ActionCreatorWithPayload<APIErrorMessage, any>;

export default function handleAPIError(
  ex: Error,
  title: string,
  dispatch: AppDispatch,
  failedAction: FailedAPIType | undefined = undefined
): APIErrorMessage {
  let error: APIErrorMessage;
  if (ex instanceof ApiError) {
    error = {
      type: 'error',
      title: title || 'Error while requesting data',
      message: `${ex.status} - ${ex.statusText} - ${ex.body.detail}`,
      duration: 3,
      showNotification: true
    };
    if (ex.status === 401) {
      dispatch(logout()); // 401 error: logout user
      error.showNotification = false;
    }
  } else if (ex instanceof CancelError) {
    error = {
      type: 'warning',
      title: title || 'Request was cancelled',
      message: 'Request was cancelled',
      duration: 0.25,
      showNotification: import.meta.env.DEV
    };
  } else if (ex instanceof TypeError) {
    error = {
      type: 'error',
      title: title || 'Error while performation operation',
      message: `Internal error`,
      duration: 3,
      showNotification: true
    };
  } else if (ex instanceof DOMException) {
    switch (ex.code) {
      case DOMException.ABORT_ERR:
        error = {
          type: 'warning',
          title: title || 'Canceled request',
          message: ex.toString(),
          duration: 0.25,
          showNotification: import.meta.env.DEV
        };
        break;
      default:
        error = {
          type: 'warning',
          title: title || ex.name,
          message: `${ex.name} - ${ex.message}`,
          duration: 3,
          showNotification: true
        };
        break;
    }
  } else {
    error = {
      type: 'error',
      title: title || 'Error',
      message: ex.toString(),
      duration: 3,
      showNotification: true
    };
  }

  if (error.showNotification) {
    dispatch(createAPIErrorNotification(error));
  }
  if (failedAction !== undefined) {
    dispatch(failedAction(error));
  }

  return error;
}
