import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Outlet, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import ErrorBoundary from './ErrorBoundary';
import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import LogoutPage from './features/user/pages/LogoutPage';
import LoginPage from './features/user/pages/LoginPage';

import { useSelector } from 'react-redux';
import { is_authenticated } from './features/user/userSelectors';

export function RequireAuth() {
  let authenticated = useSelector(is_authenticated);
  return authenticated ? <Outlet /> : <Navigate to={'/user/login'} />;
}

const ProjectsRouter = React.lazy(
  () => import('./features/projects/ProjectsRouter')
);
const WoodRouter = React.lazy(() => import('./features/wood/WoodRouter'));
const PurchasersRouter = React.lazy(
  () => import('./features/purchasers/PurchasersRouter')
);
const StockRouter = React.lazy(() => import('./features/stock/StockRouter'));
const MessagesRouter = React.lazy(
  () => import('./features/messages/MessagesRouter')
);
const TransactionsRouter = React.lazy(
  () => import('./features/transactions/TransactionsRouter')
);

const EquipmentRouter = React.lazy(
  () => import('./features/equipment/EquipmentRouter')
);
function Router() {
  const location = useLocation();

  return (
    <ErrorBoundary key={location.pathname}>
      <Suspense
        fallback={
          <Result
            icon={<LoadingOutlined spin={true} />}
            title={'Loading...'}
          ></Result>
        }
      >
        <Routes>
          <Route element={<RequireAuth />}>
            <Route index element={<Home />} />

            <Route path="messages/*" element={<MessagesRouter />} />
            <Route path="purchasers/*" element={<PurchasersRouter />} />
            <Route path="projects/*" element={<ProjectsRouter />} />
            <Route path="stock/*" element={<StockRouter />} />
            <Route path="transactions/*" element={<TransactionsRouter />} />
            <Route path="wood/*" element={<WoodRouter />} />
            <Route path="equipment/*" element={<EquipmentRouter />} />

            <Route path="user"></Route>
            <Route path="user/logout" element={<LogoutPage />} />
          </Route>

          <Route path="user">
            <Route path="login" element={<LoginPage />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Router;
