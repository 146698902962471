/**
 * Created by mathi on 12-1-2018.
 */
import {connect} from "react-redux"
import {get_user, is_authenticated, check_permission} from "../features/user/userSelectors"
import {withTranslation} from "react-i18next";
import {compose} from "redux";

export default function(mapStateToProps, mapActionsToProps){
    const mapStateToPropsExtended = function(state, ownProps){
        return Object.assign(
            (mapStateToProps)?mapStateToProps(state,ownProps):{},
            {
                is_authenticated: is_authenticated(state),
                user: get_user(state),
                check_permission: check_permission(state),
                trans: ownProps.t,
            }
        );
    }
    const mapActionsToPropsExtended = function(state, ownProps){
        return Object.assign(
            (mapActionsToProps)?mapActionsToProps(state,ownProps):{}
        );
    }
    return compose(withTranslation(), connect(mapStateToPropsExtended,mapActionsToPropsExtended))
}
