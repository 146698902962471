import { useEffect } from 'react';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { createNotification } from '../UISlice';
import { useAppDispatch } from '../../../Store';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

const checkForSWUpdatesInterval = 5 * 60 * 1000;

function ServiceworkerUpdater() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegistered(r) {
      console.log('SW Registered: ' + r);
      r &&
        setInterval(() => {
          console.log('Checking for SW updates: ' + r);
          r.update();
        }, checkForSWUpdatesInterval);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    }
  });

  const updateAvailable = offlineReady || needRefresh;

  useEffect(() => {
    if (updateAvailable) {
      dispatch(
        createNotification({
          type: 'info',
          title: t('new_version::title') as string,
          message: t('new_version::message_content') as string,
          location: 'topRight',
          duration: 20
        })
      );

      setTimeout(() => {
        updateServiceWorker(true).finally(() => {
          window.location.reload();
        });
      }, 10 * 1000);
    }
  }, [updateAvailable]);

  return <></>;
}

export default ServiceworkerUpdater;
